@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --color-primary: #f24462;

  --color-alert: #f85044;
  --color-alert-medium: #fcccc9;
  --color-alert-light: #fadedc;

  --color-success: #2ccb7f;
  --color-success-light: #dff7ec;

  --color-purple: #d671cb;

  --color-generator: #d7eeff;
  --color-scaler: #fee3e1;
  --color-translator: #cefbdd;
  --color-production: #ffe8a3;

  --color-secondary: #323232;
  --color-disabled: #a3a3a3;
  --color-dark-gray: #7a7878;
  --color-medium-gray: #dcdcdc;
  --color-gray: #e6e6e6;
  /* --color-light-gray: #f5f5f5; */
  --color-light-gray: #f5f5f5;
  --color-white: #ffffff;
  --color-light-slate: rgb(224, 227, 231);
  --color-slate: rgb(202, 209, 216);
  /* gradient */
  --gradient1-middle: #f1335f;
  --gradient1-left: #f24462;
  --gradient1-right: #ffc444;
  --gradient2-middle: #f46692;
  --gradient2-right: #f3a562;
  --linear-primary-gradient: linear-gradient(
    135deg,
    var(--gradient1-left),
    var(--gradient1-middle) 49%,
    var(--gradient1-right)
  );
  /* / */
  --font-size: 16px;
  --border-color: var(--color-gray);
  --box-shadow: 1px 1px 10px black;
  --b-radius: 10px;
  --nav-height: 60px;
  --padding-default: 12px;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  font-family: "Open Sans", sans-serif;
  font-size: var(--font-size);
  line-height: 1.5;
  background-color: white;
  color: var(--color-secondary);
}

.primary-gradient {
  background-image: var(--linear-primary-gradient);
}
.ant-image-mask:hover {
  border-radius: 100% !important;
}
.ant-image-mask {
  border-radius: 100% !important;
}

.classic_table {
  width: 100%;
  th {
    text-align: left;
  }
  tr:hover {
    cursor: pointer;
    background-color: var(--color-light-gray);
  }
  td {
    padding: 10px;
  }
}
