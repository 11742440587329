.landing-page {
  text-align: center;
  background-image: url(https://cdn.prod.website-files.com/600a7682415ab7d3aafe3a5c/60a77131308f92eca2edfa50_Ellipse%20105-min.png);
  min-height: 100vh;
  font-family: "Plus Jakarta Sans", sans-serif;
}

.header {
  margin-bottom: 20px;
}

.header-title {
  font-size: 2.5em;
  font-weight: 700;
  color: #282876;
  margin: 0;
}

.header-subtitle {
  color: var(--light-slate-grey);
  margin-bottom: 5;
  font-size: 20px;
  line-height: 26px;
}

.video-card {
  border: 1px solid #ccc;
  background: white;
  border-radius: 8px;
  overflow: hidden;
  max-width: 360px;
  margin: auto;
  padding: 10px;
}

.hero-video {
  width: 100%;
  height: auto;
}

#cta-button1 {
  margin-top: 20px;
}
#cta-button2 {
  margin-top: 15px;
}

.cta {
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  background-color: #216fed;
  border-style: none;
  border-radius: 32px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px 42px;
  font-size: 18px;
  font-weight: 500;
}

.footer {
  margin-top: 20px;
  padding: 10px 0;
}
